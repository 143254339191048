import Link from 'next/link';
import { COLORS } from 'packages/constants';
import { Span } from 'packages/elements';

import UpboundLogoIcon from '@/icons/UpboundLogoIcon';

const UpboundLogoAnchor: React.FC = () => {
  // TODO: Update Icon to not use Chakra

  return (
    <Link href="/" className="flex flex-row items-end">
      <UpboundLogoIcon
        sx={{
          color: 'white.0',
          width: '95px',
          height: '24px',
        }}
      />
      <Span textSize="Size6" color={COLORS.Purple[300]} className="ml-1" textWeight="Heavy">
        Marketplace
      </Span>
    </Link>
  );
};

export default UpboundLogoAnchor;
